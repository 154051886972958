/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Query Header Text Item
 */
export type QueryHeaderTextRecordItem = {
    /**
     * API Response Version
     */
    _ver?: number;
    /**
     * Record Unique ID
     */
    id?: number;
    /**
     * Unix Timestamp - To Seconds Precision
     */
    created?: number;
    /**
     * Unix Timestamp - To Seconds Precision
     */
    updated?: number;
    /**
     * Query Header Text
     */
    text?: string;
    /**
     * Record Unique ID
     */
    queryHeaderId?: number;
    /**
     * Language ID
     */
    languageId?: number;
    /**
     * Field
     */
    field?: QueryHeaderTextRecordItem.field;
    /**
     * Flag for is translated
     */
    translated?: boolean;
    /**
     * Flag for original
     */
    original?: boolean;
    /**
     * Flag for is failed
     */
    failed?: boolean;
    /**
     * Name of Language
     */
    languageName?: string;
}

export namespace QueryHeaderTextRecordItem {

    /**
     * Field
     */
    export enum field {
        HISTORY = 'history',
        TEXT = 'text',
    }


}
