<template>
  <div>
    <review-item-simple
      v-if="clientData['askSelectPlan'] && notEmpty(clientData['planName'])"
      tag="selectPlan"
      :value="clientData['planName']"
    />
    <review-item-simple
      v-if=" notEmpty(clientData['diagnosisId'])"
      tag="diagnosis"
      :value="clientData['diagnosis']"
    ></review-item-simple>

    <review-item-text
      v-if="clientData['disclosureAccepted']"
      tag="disclosure"
      :value="clientData['disclosureText']"
    ></review-item-text>

    <review-item-simple
      v-if="clientData['askSelectPool'] &&  notEmpty(clientData['poolId'])"
      tag="poolSelect"
      :value="clientData['poolName']"
    />

    <review-item-simple
      v-if="clientData['contextName']"
      tag="queryContext"
      :value="clientData['contextName']"
    ></review-item-simple>

    <review-item-simple
      v-if="clientData['askGenderOptOut'] && clientData['ageGenderOptOut'] !== undefined"
      tag="ageGenderOptOut"
      :value="clientData['ageGenderOptOut'] ? 'No' : 'Yes'"
    />
    <review-item-simple
      v-if="notEmpty(clientData['genderId'])"
      tag="gender"
      :value="clientData['genderId']"
    ></review-item-simple>

    <review-item-simple
      v-if="notEmpty(clientData['ageMagnitude']) && clientData['ageMagnitude'] > 0"
      tag="age"
      :value="`${clientData['ageMagnitude']} ${clientData['ageUnits']}`"
    ></review-item-simple>

    <review-item-simple
      v-if="notEmpty(clientData['dob']) "
      tag="dob"
      :value="formatDate(clientData['dob'])"
    ></review-item-simple>

    <review-item-text
      v-if="notEmpty(clientData['history'])"
      :tag="computedHistoryTag"
      :value="clientData['history']"
    />
    <review-item-text
      v-if="notEmpty(clientData['question'])"
      tag="question"
      :value="clientData['question']"
    />

    <review-item-simple
      v-if="notEmpty(clientData['media']) && clientData['media'].length > 1 && notEmpty(clientData['medium'])"
      tag="medium"
      :value="clientData['medium']"
    ></review-item-simple>

    <review-item-simple
      v-if="clientData['anonOption'] && clientData['anonymous'] !== undefined"
      tag="anonymous"
      :value="clientData['anonymous'] ? 'Yes' : 'No'"
    ></review-item-simple>

    <review-item-schedule
      v-if="clientData['medium'] !== 'messenger' && clientData['times'] !== undefined && clientData['times'].length"
      tag="schedule"
      :value="clientData['times'].map(x => x).join(', ')"
      :times="clientData['formattedTimes']"
    ></review-item-schedule>

    <review-item-simple
      v-if="clientData['expertSelectionMode'] === 'user_choice' && clientData['expertSelectionChoice'] !== undefined"
      tag="expertSource"
      :value="clientData['expertSelectionChoice']"
    ></review-item-simple>

    <review-item-html
      v-if="clientData.termsAccepted && clientData.termsText && clientData.termsText.trim() !== ''"
      tag="terms"
      :value="clientData.termsText"
    />

    <review-item-submit v-if="canSubmit" id="submitQuery" tag="submitQuery" :canEdit="false"
                        @submit="$emit('submit')" />
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import ReviewItemSimple from "./ReviewItemSimple.vue";
import ReviewItemSchedule from "./ReviewItemSchedule.vue";
import ReviewItemText from "./ReviewItemText.vue";
import ReviewItemHtml from "./ReviewItemHtml.vue";
import ReviewItemSubmit from "./ReviewItemSubmit.vue";
import { DateTime } from "luxon";

import { ChatBotResponse } from "@/service";
import { UserType } from "@/config";

export default Vue.extend({
  components: {
    ReviewItemSimple,
    ReviewItemText,
    ReviewItemHtml,
    ReviewItemSchedule,
    ReviewItemSubmit
  },
  props: {
    queryBuilderData: {
      type: Object as PropType<ChatBotResponse>,
      required: false
    }
  },
  computed: {
    clientData(): Record<string, any> {
      return this.queryBuilderData?.action.clientData ?? {};
    },
    canSubmit(): boolean {
      // Has reached the end and if there are terms they have been accepted
      return this.clientData["completed"] && (!this.clientData["termsRequired"] || this.clientData["termsAccepted"]);
    },
    computedHistoryTag() {
      return this.clientData["userType"] === UserType.CLIENT ? 'history' : 'summary';
    }
  },
  methods: {
    formatDate(d: string): string {
      console.log(d);
      const dt = DateTime.fromFormat(d, "y-M-d");
      return dt.toLocaleString(DateTime.DATE_SHORT);
    },
    notEmpty(value: any): boolean {
      // Check if value is undefined
      if (value === undefined || value === null) {
        return false;
      }
      // Convert value to string and then perform trim check
      const trimmedValue = typeof value === "string" ? value.trim() : String(value);
      return trimmedValue !== "";
    }


  },
  watch: {
    canSubmit(val) {
      if (val) {
        // Scroll to bottom
        this.$nextTick(() => {
          document.getElementById("submitQuery")?.scrollIntoView();
        });
      }
    }
  }
});
</script>