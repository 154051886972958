<template>
  <div class="chat-bot-component mb-0" :style="styles">
    <div ref="scroller" class="chat-bot-scroller pl-2 pr-6  py-4 mb-10">
      <component
        v-for="(item, index) in actionComponents"
        :key="item.index"
        :is="item.comp"
        :action="actions[item.index]"
        @callback="next($event)"
        @log="logMessage"
        @messageAdded="scrollIt"
        @actionClick="actionClick"
        :wizardMode="wizardMode"
        v-show="showAllActions || index === 0"
      />
      <typing-indicator v-if="loading" />
    </div>
    <v-img
      v-if="showAvatar && $vuetify.breakpoint.mdAndUp"
      contain
      position="center bottom"
      src="@/assets/doctorImage.png"
      class="avatar-pic"
    />
  </div>
</template>

<script lang="ts">
/*
    Main ChatBot Component.
    Overall plan is this accepts as props a function which accepts the current state, and returns instructions.
    At each step, the client code can save additional info which will be present in the next response
*/
import Vue, { PropType, VueConstructor } from "vue";
import ChatBotActionComponent from "./Actions/Action.vue";
import ChooseOneActionComponent from "./Actions/ChooseOneAction.vue";
import ChooseMultipleActionComponent from "./Actions/ChooseMultipleAction.vue";
import TextActionComponent from "./Actions/TextAction.vue";
import AutoCompleteActionComponent from "./Actions/AutocompleteAction.vue";
import AgeActionComponent from "./Actions/AgeAction.vue";
import DateActionComponent from "./Actions/DateAction.vue";
import DobActionComponent from "./Actions/DobAction.vue";
import LongTextActionComponent from "./Actions/LongTextAction.vue";
import ScheduleActionComponent from "./Actions/ScheduleAction.vue";
import DisclosureActionComponent from "./Actions/DisclosureAction.vue";

import { scrollToBottom } from "@/util/scrollUtils";

import TypingIndicator from "./Actions/TypingIndicator.vue";
import { ChatBotAction, ChatBotEvent, ChatBotMessage, ChatBotMessageContainer, ChatBotResponse } from "@/service";

export type ChatBotCallback = (event: ChatBotEvent) => Promise<ChatBotResponse>;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ChatBotClientData = Record<string, any>;

export default (Vue as VueConstructor<
  Vue & {
    $refs: {
      scroller: InstanceType<typeof HTMLElement>;
    };
  }
>).extend({
  name: "chatbot",
  components: {
    ChatBotActionComponent,
    ChooseOneActionComponent,
    ChooseMultipleActionComponent,
    TextActionComponent,
    AutoCompleteActionComponent,
    AgeActionComponent,
    DateActionComponent,
    DobActionComponent,
    LongTextActionComponent,
    ScheduleActionComponent,
    DisclosureActionComponent,
    TypingIndicator,
  },
  props: {
    init: {
      type: Object as PropType<ChatBotClientData>,
      required: true
    },
    callback: {
      type: Function as PropType<ChatBotCallback>,
      required: true
    },
    styles: {
      type: [String, Object],
      default: ""
    },
    showAvatar: {
      type: Boolean,
      default: true
    },
    wizardMode: {
      type: Boolean,
      default: false
    },
    showAllActions: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    // For now
    actions: [] as Array<ChatBotMessageContainer | ChatBotAction>,
    onDeck: null as ChatBotAction | null,
    onDeckIdent: 0,
    //actions: [] as Array<ChatBotAction>,
    messages: [] as Array<string>,
    timer: null as number | null,
    loading: false,
    horizon: 0,
  }),
  computed: {
    actionComponents(): Array<{ index: number; comp: string }> {
      return this.actions
        .map((a, index) => ({ index, comp: this.mapComponent(a.action) }))
        .filter(({ index }) => !this.wizardMode || index >= this.horizon);
    },
    trueActions(): Array<ChatBotAction> {
      return this.actions.filter(a => a.isAction) as Array<ChatBotAction>;
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    clientData(): Record<string, any> {
      if (!this.trueActions.length) {
        return {};
      }
      return this.trueActions[this.trueActions.length - 1].clientData;
    }
  },
  methods: {
    mapComponent(action: string): string {
      switch (action) {
        case "choose-one":
          return "ChooseOneActionComponent";
        case "choose-many":
          return "ChooseMultipleActionComponent";
        case "text-line":
          return "TextActionComponent";
        case "text-long":
          return "LongTextActionComponent";
        case "auto-complete":
          return "AutoCompleteActionComponent";
        case "disclosure":
          return "DisclosureActionComponent";
        case "age":
          return "AgeActionComponent";
        case "date":
          return "DateActionComponent"; // may need different control for dob
        case "dob":
          return "DobActionComponent"; // may need different control for dob
        case "schedule":
          return "ScheduleActionComponent";
        case "say":
        default:
          return "ChatBotActionComponent";
      }
    },
    async next(e: ChatBotEvent): Promise<void> {
      try {
        this.loading = true;
        // console.log("Inside Next Function");
        // console.log(e);

        this.horizon = this.actions.length;

        // The next action should probably provide any necessary response rather than format it here
        if (e.responses.length) {
          this.log(e.responses.map(l => l.toString()).join(", "));
        }


        const resp: ChatBotResponse | undefined = await this.callback(e);
        this.loading = false;

        // Need to push echoes and wait for them to complete.
        if (!resp) {
          this.onDeckIdent = 0;
        } else {
          if (resp.echo.messages.length) {
            // console.log(resp.echo);
            this.onDeck = resp.action;
            this.onDeckIdent = resp.echo.ts;
            this.pushAction(resp.echo);
          } else {
            this.onDeckIdent = 0;
            this.pushAction(resp.action);
          }
        }
      } catch (err) {
        console.log({ err });
        this.loading = false;
        if (e.actionContext === "submit") {
          this.$emit("submitError");
        }
      }
    },

    async pushAction(action: ChatBotAction | ChatBotMessageContainer): Promise<void> {
      const delay = (action as any).delay;
      if (delay) {
        await this.sleep(delay);
      }
      if (action.clear) {
        this.actions.splice(0, this.actions.length);
      }
      this.actions.push(action);
    },
    async actionClick(context: string): Promise<void> {
      const event: ChatBotEvent = {
        type: "action",
        ts: Date.now(),
        clientData: Object.assign({}, this.clientData),
        actionContext: context,
        responses: []
      };

      // Do we need to cancel/remove current action first?
      if (this.actions[this.actions.length - 1].isAction) {
        this.actions.splice(-1);
      }
      this.next(event);
    },
    async onSubmit(): Promise<void> {
      const event: ChatBotEvent = {
        type: "action",
        ts: Date.now(),
        clientData: Object.assign({}, this.clientData),
        actionContext: "submit",
        responses: []
      };
      this.next(event);
    },

    logMessage(m: ChatBotMessage): void {
      // console.log(m);
      if (m.content && ["text", "html"].includes(m.type)) {
        this.log(m.content);
      }
    },
    async sleep(ms: number): Promise<void> {
      return new Promise(resolve =>
        setTimeout(() => {
          this.timer = null;
          resolve();
        }, ms)
      );
    },
    log(m: string): void {
      this.messages.push(m);
    },
    reset(): void {
      this.actions.splice(0, this.actions.length);
      this.messages.splice(0, this.messages.length);
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.next({ type: "init", ts: Date.now(), responses: [], clientData: [] });
    },
    async scrollIt(ident?: number): Promise<void> {
      this.$nextTick(() => scrollToBottom(this.$refs.scroller));
      if (ident && ident == this.onDeckIdent && this.onDeck) {
        this.onDeckIdent = 0;
        this.pushAction(this.onDeck);
        this.onDeck = null;
      }
    },
    getQueryParam(param: string) {
      return this.$route.query[param];
    },
  },
  created(): void {
    const initEvent: ChatBotEvent = {
      type: "init",
      ts: Date.now(),
      responses: [],
      clientData: {}
    };

    // Add draftId to clientData if it exists in query parameters
    const queryParam = this.getQueryParam("draftId");
    if (queryParam) {
      initEvent.clientData.queryDraftId = queryParam ; // Push the value to the array
    }

    this.next(initEvent);
    this.$eventBus.$on("actionClick", this.actionClick);
    this.$eventBus.$on("newQuerySubmit", this.onSubmit);
  },
  destroyed() {
    this.$eventBus.$off("newQuerySubmit", this.onSubmit);
    this.$eventBus.$off("actionClick", this.actionClick);
    if (this.timer) {
      clearTimeout(this.timer);
    }
  }
});
</script>

<style lang="scss" scoped>
.chat-bot-component {
  position: relative;

  .avatar-pic {
    position: absolute;
    width: 15rem;
    height: 15rem;
    bottom: 0;
    left: 0;
    margin-left: -14rem;
    // background-color: rgba(80, 80, 80, 0.2);
  }

  .chat-bot-scroller {
    overflow-y: auto;
    position: absolute;
    //bottom: 0px;
    right: 0px;
    left: 0px;
    top: 0px;
    /* this is the key */
    max-height: 100%;
  }

  :deep(.cb-message) {
    margin-bottom: 10px;
    display: flex;
    .cb-message--edited {
      opacity: 0.7;
      word-wrap: normal;
      font-size: xx-small;
      text-align: center;
    }
  }

  :deep(.cb-message--content) {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  :deep(.cb-message--content.sent) {
    // justify-content: flex-end;
    align-items: flex-end;
  }
  :deep(.cb-message--content.received) {
    // justify-content: flex-end;
    align-items: flex-start;
  }

  :deep(.cb-message--content.system) {
    justify-content: center;
  }

  :deep(.cb-message--content.sent .cb-message--avatar) {
    display: none;
  }

  :deep(.cb-message--avatar) {
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;
    min-width: 30px;
    min-height: 30px;
    border-radius: 50%;
    align-self: center;
    margin-right: 15px;
  }

  :deep(.cb-message--meta) {
    font-size: xx-small;
    margin-bottom: 0px;
    color: white;
    text-align: center;
  }

  @media (max-width: 450px) {
    :deep(.sc-message) {
      width: 80%;
    }
  }
}

pre.console {
  overflow-x: auto;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
}
</style>
