<template>
  <v-menu v-if="user && !$vuetify.breakpoint.xsOnly" :class="specificity ? 'prioritize-specificity' : ''" rounded="lg"
    bottom left offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn text color="primary" class="d-flex align-center fixed-size px-2" v-bind="attrs" v-on="on">
        <v-avatar v-if="authUser" size="42" color="primary" style="margin-right: -12px;" xclass="mr-2">
          <v-img v-if="authUser.avatar && authUser.avatar.trim() != ''" :src="authUser.avatar"></v-img>
          <span v-else class="white--text text-body" v-text="authInitials"></span>
        </v-avatar>
        <v-avatar size="42" color="primary" class="mr-2">
          <v-img v-if="user.avatar && user.avatar.trim() != ''" :src="user.avatar"></v-img>
          <span v-else class="white--text text-body" v-text="initials"></span>
        </v-avatar>
        <div class="text-nowrap">{{ user.resolvedDisplayName }}</div>
      </v-btn>
    </template>

    <v-list>
      <v-list-item-group color="primary">
        <v-list-item v-for="item in items" :key="item.id" :id="item.id" :to="item.route"
          @click="onMenuItemSelected(item)">
          <v-list-item-content>
            <v-list-item-title>{{ $t(`appBar.userMenu.${item.id}`) }}</v-list-item-title>
            <v-list-item-subtitle v-if="item.subtitle">{{ item.subtitle }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-menu>
  <v-navigation-drawer v-else-if="user" :class="specificity ? 'prioritize-specificity' : ''" right clipped app
    v-model="isMenuOpen" disable-route-watcher disable-resize-watcher>
    <template v-slot:prepend>
      <v-list-item id="userMenu" class="flex text-center" link :to="{ name: 'Account' }">
        <v-list-item-content>
          <v-list-item-title>
            <v-avatar v-if="authUser" size="42" color="primary" style="margin-right: -12px;" xclass="mr-2">
              <v-img v-if="authUser.avatar && authUser.avatar.trim() != ''" :src="authUser.avatar"></v-img>
              <span v-else class="white--text text-body" v-text="authInitials"></span>
            </v-avatar>
            <v-avatar size="42" color="primary" class="mr-2">
              <v-img v-if="user.avatar && user.avatar.trim() != ''" :src="user.avatar"></v-img>
              <span v-else class="white--text text-body" v-text="initials"></span>
            </v-avatar>
          </v-list-item-title>
          <v-list-item-subtitle color="primary" class="mt-2 primary--text"> {{ user.resolvedDisplayName
            }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </template>
    <v-divider></v-divider>
    <v-list>
      <v-list-item-group color="primary">
        <v-list-item v-for="item in items" :key="item.id" :id="item.id" :to="item.route"
          @click="onMenuItemSelected(item)">
          <v-list-item-content>
            <v-list-item-title>{{ $t(`appBar.userMenu.${item.id}`) }}</v-list-item-title>
            <v-list-item-subtitle v-if="item.subtitle">{{ item.subtitle }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-navigation-drawer>
</template>

<script lang="ts">
import Vue from "vue";
import { UserType } from "@/config";

import { createHelpers } from "vuex-typescript-interface";
import { RootStoreInterface } from "@/store/types";

import { i18n } from "@/plugins/i18n";

const { mapGetters, mapActions } = createHelpers<RootStoreInterface>();

const getters = mapGetters(["isLoggedIn", "user", "authUser", "userCanPost"]);
const actions = mapActions(["logout", "cancelLoginAs"]);

type MenuItem = {
  title: string;
  subtitle?: string;
  id: string;
  route?: {
    name: string,
    path?: string,
    hash?: string
  }
};

export default Vue.extend({
  name: "UserMenu",
  props: {
    // State of the drawer/menu button
    open: {
      type: Boolean,
      default: false
    },
    specificity: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...getters,
    isMenuOpen: {
      get(): boolean {
        return this.open;
      },
      set(newValue: boolean) {
        this.$emit('update:open', newValue)
      }
    },

    isCsr(): boolean {
      return this.isLoggedIn && this.user?.userType == UserType.CSR;
    },
    initials(): string {
      return (
        (this.user?.firstName?.substring(0, 1).toUpperCase() ?? "") +
        (this.user?.lastName?.substring(0, 1).toUpperCase() ?? "?")
      );
    },
    authInitials(): string {
      return (
        (this.authUser?.firstName?.substring(0, 1).toUpperCase() ?? "") +
        (this.authUser?.lastName?.substring(0, 1).toUpperCase() ?? "?")
      );
    },
    items(): Array<MenuItem> {
      let mlist = [];
      if (this.isLoggedIn && !this.isCsr && this.userCanPost) {
        mlist.push({ title: "Start a Discussion", id: "askQuestion", route: { name: "NewQuery" } });
      }
      mlist.push({ title: "Contact Us", id: "contactUs" });
      mlist.push({ title: "Dashbooard", id: "dashboard", route: { name: this.dashboardRouteName, path: this.dashboardRouteName == "Home" ? "/" : undefined } });
      if (this.isLoggedIn && ![1, 3].includes(this.user?.userType ?? 1)) {
        mlist.push({ title: "My Discussions", id: "discussions", route: { name: "Discussions" } });
      }
      mlist.push({ title: "User Profile", id: "profile", route: { name: "Account" } });
      if (this.isLoggedIn && this.authUser) {
        mlist.push({ title: "Return to your account", subtitle: this.authUser.displayName || (this.authUser as any).name || "CSR", id: "cancelLoginAs" });
      }
      mlist.push({ title: "Sign Out", id: "signout" });

      //move "Contact Us" to the second position
      if (mlist.length > 1 && mlist[1].id !== "contactUs") {
        for (let i = 0; i < mlist.length; i++) {
          if (mlist[i].id === "contactUs") {
            const contactUsItem = mlist.splice(i, 1)[0];
            mlist.splice(1, 0, contactUsItem);
            break;
          }
        }
      }
      return mlist;
    },
    dashboardRouteName(): string {
      if (this.isLoggedIn && this.user && this.user.userType) {
        switch (this.user.userType) {
          case 1:
            return "MemberLobby"; // "/member/lobby"
          case 3:
            return "QueryIndex"; // "/admin/queries"
          default:
            return "Home"; // "/"
        }
      }
      return "Home"; // "/"
    },
    isExpert(): boolean {
      return this.user?.noeticExpert ?? false;
    }
  },
  methods: {
    ...actions,
    onMenuItemSelected(item: MenuItem) {
      switch (item.id) {
        case "discussions":
          this.$router.push(Object.assign({ hash: this.isExpert ? "#requiresAction" : "#newOpportunities" }, item.route))
          break;
        case "cancelLoginAs":
          this.cancelLoginAs();
          break;
        case "signout":
          this.logout();
          break;
        case "contactUs":
          const mail = i18n.t(`fields.supportOnviv`) as string;
          window.location.href = `mailto:${mail}`;
          break;

        default:
          if (item.route) {
            this.$router.push(item.route).catch(() => {/* Silent the error */ });
          }
      }
    }
  }
});
</script>

<style lang="scss" scoped>
#contactUs.v-list-item--link {

  &:before,
  & .v-list-item__title {
    background-color: transparent;
    color: #000000
  }

  &:hover::before {
    background-color: rgba(0, 0, 0, 0.87);
    opacity: 0.04;
  }
}

// 
.user-menu .v-list-item--active {
  color: var(--v-anchor-base);
}

.rounded-lg {
  border-radius: 10px !important;
}

.fixed-size {
  :deep(.v-btn__content) {
    max-width: 180px !important;
    justify-content: left;
  }

  .text-nowrap {
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>
