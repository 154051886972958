/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { QueryArchiveDisplayItem } from './QueryArchiveDisplayItem';
import type { QueryHeaderTextRecordItem } from './QueryHeaderTextRecordItem';
import type { QuerySurveyInfo } from './QuerySurveyInfo';
import type { TagsRecordItem } from './TagsRecordItem';

/**
 * Query Header Item for Display
 */
export type QueryInteractionDisplayItem = {
    /**
     * API Response Version
     */
    _ver: number;
    /**
     * Interaction uuid (qe_uuid)
     */
    key: string;
    /**
     * Record Unique ID for Query Expert
     */
    participantId: number;
    /**
     * Can the current user post attachments?
     */
    canAttach: boolean;
    /**
     * Can the current user post responses?
     */
    canRespond: boolean;
    /**
     * Display Option
     */
    showAttachmentsInHeader: boolean;
    /**
     * Is user interacting as an expert?
     */
    isExpert: boolean;
    /**
     * Expert Status on this Query
     */
    expertStatus?: string;
    /**
     * Is submitter? (or team member)
     */
    isUser: boolean;
    /**
     * Is this a pending invitation for this user?
     */
    isInvite: boolean;
    /**
     * Various statuses will show the invite with potential error messages
     */
    isShowInvite?: boolean;
    /**
     * Disclaimer Doc Id
     */
    disclaimer: string;
    /**
     * Latest draft response for this user
     */
    draft?: string;
    /**
     * Record Unique ID for Query Header
     */
    queryId: number;
    /**
     * Record Unique ID for Query Header Draft ID
     */
    queryDraftId?: number;
    /**
     * type
     */
    queryType: QueryInteractionDisplayItem.queryType;
    /**
     * Unix Timestamp - To Seconds Precision
     */
    created: number;
    /**
     * Unix Timestamp - To Seconds Precision
     */
    updated?: number;
    /**
     * Unix Timestamp - To Seconds Precision. Release/Invite time
     */
    inviteTime?: number;
    /**
     * Unix Timestamp - To Seconds Precision
     */
    closeTime?: number;
    /**
     * Flag for prevent expert selction automation
     */
    preventInvite?: boolean;
    /**
     * Formatted Date/Time String
     */
    submitted: string;
    /**
     * Diagnosis ID
     */
    diagnosisId: number;
    /**
     * Diagnosis Name
     */
    diagnosisName: string;
    /**
     * Query Status
     */
    status: QueryInteractionDisplayItem.status;
    /**
     * Query Status -- Display Only
     */
    displayStatus: QueryInteractionDisplayItem.displayStatus;
    /**
     * gender
     */
    gender: QueryInteractionDisplayItem.gender;
    /**
     * Age Unit
     */
    ageUnit: QueryInteractionDisplayItem.ageUnit;
    /**
     * Age Magnitude
     */
    ageMagnitude?: number;
    dob?: string;
    /**
     * Media Type of Query
     */
    mediaType: QueryInteractionDisplayItem.mediaType;
    /**
     * Flag for translated
     */
    translated: boolean;
    /**
     * Asker context, if provided
     */
    contextName?: string;
    /**
     * Plan supports team queries
     */
    isShared: boolean;
    survey?: QuerySurveyInfo;
    /**
     * Status of Live Session. One of 'pending','sessionComplete','paymentComplete','cancelled', null
     */
    liveStatus?: string;
    /**
     * Meeting Id - Must be Unique
     */
    meetingId?: string;
    /**
     * Unix Timestamp - To Seconds Precision
     */
    meetingTime?: number;
    /**
     * Meeting time formatted for user's timezone
     */
    formattedMeetingTime?: string;
    /**
     * Dial-in Numbers
     */
    dialIn?: Array<string>;
    /**
     * Dial-in PIN
     */
    meetingPin?: string;
    /**
     * Meeting URL
     */
    mtgUrl?: string;
    /**
     * Video ready for join
     */
    canJoinVideo?: boolean;
    /**
     * Zoom Meeting Id
     */
    zoomId?: string;
    /**
     * Zoom Connect Code
     */
    zoomConnectCode?: string;
    /**
     * User is Host
     */
    zoomIsHost?: boolean;
    /**
     * Zoom Dial In
     */
    zoomDialIn?: string;
    /**
     * Waiting Room
     */
    waitingRoomUrl?: string;
    /**
     * Proposed times for live session
     */
    proposedTimes?: Array<{
        ts?: number,
        formatted?: string,
    }>;
    /**
     * One of unscheduled, early, ended, joinable, awaitHost
     */
    sessionStatus?: string;
    /**
     * Plan Id
     */
    planId?: number;
    /**
     * Plan Name
     */
    planName?: string;
    /**
     * Plan Display Title
     */
    planTitle?: string;
    /**
     * Grant Id
     */
    grantId?: number;
    /**
     * Grant Name
     */
    grantName?: string;
    /**
     * Display Title for Grant
     */
    grantDisplayTitle?: string;
    /**
     * CME Front Matter
     */
    cmeFrontMatter?: string;
    /**
     * CME Title
     */
    cmeTitle?: string;
    /**
     * CME Description
     */
    cmeDescription?: string;
    /**
     * Displayable Author name
     */
    userNameDisplay?: string;
    /**
     * Actual Author name
     */
    userName: string;
    /**
     * Query user active?
     */
    userActive?: boolean;
    /**
     * User ID
     */
    userId?: number;
    /**
     * User Type ID
     */
    userType?: number;
    /**
     * User Type Display
     */
    typeDisplay?: string;
    /**
     * User Profile Img
     */
    avatar?: string;
    /**
     * Geographic State of Author
     */
    userState?: string;
    /**
     * Geographic Country of Author
     */
    userCountry?: string;
    /**
     * Expert Selection Mode
     */
    expertSelectionMode?: QueryInteractionDisplayItem.expertSelectionMode;
    /**
     * Expert Selection State
     */
    expertSelectionState?: string;
    /**
     * Pool Id
     */
    poolId?: number;
    /**
     * Pool Name
     */
    poolName?: string;
    /**
     * Aggregated Plan/Pool Names for Display
     */
    planPoolName?: string;
    /**
     * Query Header Text
     */
    text?: string;
    /**
     * History
     */
    history?: string;
    /**
     * Is the panel already filled from this users perspective.
     */
    isPanelFull?: boolean;
    /**
     * Can the expert join as an unpaid panelist?
     */
    allowUnpaid?: boolean;
    /**
     * Unix Timestamp - To Seconds Precision. Invitation expiry time
     */
    inviteExpires?: number;
    /**
     * Flag for CSR Review
     */
    csrReview?: boolean;
    /**
     * Max panel size
     */
    maxExperts?: number;
    /**
     * Category (qh_query_type)
     */
    category?: string;
    /**
     * Translations for this header's text field. Maybe empty array.
     */
    textTranslations?: {
        data: Array<QueryHeaderTextRecordItem>,
    };
    /**
     * Translations for this header's history field. Maybe empty array.
     */
    historyTranslations?: {
        data: Array<QueryHeaderTextRecordItem>,
    };
    /**
     * Archive listing.
     */
    archives: {
        data: Array<QueryArchiveDisplayItem>,
    };
    tagsGlobal?: {
        data?: Array<TagsRecordItem>,
    };
    tagsTeam?: {
        data?: Array<TagsRecordItem>,
    };
    tagsUser?: {
        data?: Array<TagsRecordItem>,
    };
}

export namespace QueryInteractionDisplayItem {

    /**
     * type
     */
    export enum queryType {
        NORMAL = 'normal',
        BLINDED = 'blinded',
        COMMUNAL = 'communal',
        PRIVATE = 'private',
        VTB = 'vtb',
        EMO = 'emo',
    }

    /**
     * Query Status
     */
    export enum status {
        OPEN = 'open',
        CLOSED = 'closed',
        ANSWERED = 'answered',
        HELD = 'held',
        PENDING_CHARGE = 'pending_charge',
        CHARGED = 'charged',
        CREATE = 'create',
        CSRHOLD = 'csrhold',
        CANCELLED = 'cancelled',
        PENDING_SURVEY = 'pending_survey',
        RELEASE = 'release',
    }

    /**
     * Query Status -- Display Only
     */
    export enum displayStatus {
        OPEN = 'open',
        CLOSED = 'closed',
        CANCELLED = 'cancelled',
        UNRELEASED = 'unreleased',
    }

    /**
     * gender
     */
    export enum gender {
        UNSPECIFIED = 'unspecified',
        FEMALE = 'female',
        MALE = 'male',
        TRANSMALE = 'transmale',
        TRANSFEMALE = 'transfemale',
        PRETRANSMALE = 'pretransmale',
        PRETRANSFEMALE = 'pretransfemale',
        INDETERMINATE = 'indeterminate',
    }

    /**
     * Age Unit
     */
    export enum ageUnit {
        DAY = 'day',
        WEEK = 'week',
        MONTH = 'month',
        YEAR = 'year',
    }

    /**
     * Media Type of Query
     */
    export enum mediaType {
        MESSENGER = 'messenger',
        AUDIO = 'audio',
        VIDEO = 'video',
    }

    /**
     * Expert Selection Mode
     */
    export enum expertSelectionMode {
        GRANT_FIRST = 'grant_first',
        PLAN_FIRST = 'plan_first',
        ALL = 'all',
        USER_CHOICE = 'user_choice',
        PLAN_ONLY = 'plan_only',
        GRANT_ONLY = 'grant_only',
        PLAN_SELECT = 'plan_select',
    }


}
