/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { LiveSessionStatusInfo } from '../models/LiveSessionStatusInfo';
import type { meta } from '../models/meta';
import type { QueryApplicableExpert } from '../models/QueryApplicableExpert';
import type { QueryArchiveInfo } from '../models/QueryArchiveInfo';
import type { QueryAttachmentInfo } from '../models/QueryAttachmentInfo';
import type { QueryDisplayUpdate } from '../models/QueryDisplayUpdate';
import type { QueryInteractionDisplayItem } from '../models/QueryInteractionDisplayItem';
import type { QueryInteractionResponseItem } from '../models/QueryInteractionResponseItem';
import type { QueryInviteItem } from '../models/QueryInviteItem';
import type { QueryInviteRecordItem } from '../models/QueryInviteRecordItem';
import type { QueryParticipantListItem } from '../models/QueryParticipantListItem';
import { request as __request } from '../core/request';

export class InteractionService {

    /**
     * Accept an invitation
     * Accept an invitation
     * @result QueryDisplayUpdate successful operation
     * @throws ApiError
     */
    public static async acceptInvitation({
        key,
        requestBody,
    }: {
        /** The UUID for the individuals interaction (query_experts.qe_uuid) or Query ID **/
        key: string,
        /** Post Response **/
        requestBody?: {
            /**
             * Accepted meeting time
             */
            timestamp?: number,
            /**
             * Accepts unpaid status for this query.
             */
            unpaid?: boolean,
        },
    }): Promise<QueryDisplayUpdate> {
        const result = await __request({
            method: 'POST',
            path: `/user/interaction/${key}/accept`,
            body: requestBody,
            errors: {
                403: `Error: Forbidden`,
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Accept specific Terms
     * Accept terms
     * @result any successful operation
     * @throws ApiError
     */
    public static async acceptTerms({
        key,
        requestBody,
    }: {
        /** The UUID for the individuals interaction (query_experts.qe_uuid) or Query ID **/
        key: string,
        /** Post Response **/
        requestBody: {
            /**
             * CMD Document Id
             */
            cmsId?: string,
            /**
             * CMS Document Language
             */
            language: string,
            /**
             * CMS Document Last Published
             */
            updated: string,
        },
    }): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/user/interaction/${key}/acceptTerms`,
            body: requestBody,
            errors: {
                403: `Error: Forbidden`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Add CSR Note
     * Add CSR Note
     * @result QueryDisplayUpdate successful operation
     * @throws ApiError
     */
    public static async addCsrNote({
        key,
        requestBody,
    }: {
        /** The UUID for the individuals interaction (query_experts.qe_uuid) or Query ID **/
        key: string,
        /** New Panel Size **/
        requestBody: {
            /**
             * Note text
             */
            text?: string,
        },
    }): Promise<QueryDisplayUpdate> {
        const result = await __request({
            method: 'POST',
            path: `/csradmin/interaction/${key}/addCsrNote`,
            body: requestBody,
            errors: {
                403: `Error: Forbidden`,
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Query Invite List
     * Returns one or more matching records
     * @result any successful operation
     * @throws ApiError
     */
    public static async listQueryInvites({
        key,
    }: {
        /** The UUID for the individuals interaction (query_experts.qe_uuid) or Query ID **/
        key: string,
    }): Promise<{
        data: Array<QueryInviteItem>,
        meta?: meta,
    }> {
        const result = await __request({
            method: 'GET',
            path: `/user/interaction/${key}/invites`,
            errors: {
                403: `Error: Forbidden`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Create a new query header invite
     * Creates a record based on data given
     * @result QueryInviteRecordItem Successful operation
     * @throws ApiError
     */
    public static async addQueryInvite({
        key,
        requestBody,
    }: {
        /** The UUID for the individuals interaction (query_experts.qe_uuid) or Query ID **/
        key: string,
        /** Query invite object to be created **/
        requestBody: {
            /**
             * Invitee Name
             */
            name: string,
            /**
             * Invitee Email
             */
            email: string,
            /**
             * Invitee Phone
             */
            phone?: string,
            /**
             * type
             */
            type: 'lovedone' | 'subject' | 'provider' | 'poa' | 'blinded_voice_up' | 'blinded_voice_down' | 'blinded_voice_none' | 'blinded_voice_mute',
        },
    }): Promise<QueryInviteRecordItem> {
        const result = await __request({
            method: 'POST',
            path: `/user/interaction/${key}/invites`,
            body: requestBody,
            errors: {
                400: `Error: Update Failed`,
                403: `Error: Forbidden`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Release Query from CSR Hold
     * Release Query from CSR Hold
     * @result QueryDisplayUpdate successful operation
     * @throws ApiError
     */
    public static async addTeamMember({
        key,
        requestBody,
    }: {
        /** The UUID for the individuals interaction (query_experts.qe_uuid) or Query ID **/
        key: string,
        /** Add team mamber **/
        requestBody: {
            /**
             * ID of user to add
             */
            userId?: number,
        },
    }): Promise<QueryDisplayUpdate> {
        const result = await __request({
            method: 'POST',
            path: `/csradmin/interaction/${key}/addTeamMember`,
            body: requestBody,
            errors: {
                403: `Error: Forbidden`,
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Cancel a Query
     * Cancel a Query
     * @result QueryDisplayUpdate successful operation
     * @throws ApiError
     */
    public static async cancelQuery({
        key,
        forceCancel = false,
    }: {
        /** The UUID for the individuals interaction (query_experts.qe_uuid) or Query ID **/
        key: string,
        /** Force Query to Cancel regardless of status **/
        forceCancel?: boolean,
    }): Promise<QueryDisplayUpdate> {
        const result = await __request({
            method: 'POST',
            path: `/csradmin/interaction/${key}/cancelQuery`,
            query: {
                'forceCancel': forceCancel,
            },
            errors: {
                403: `Error: Forbidden`,
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Deactivate Query Invitation
     * Deactivate a Query Invitation
     * @result any successful operation
     * @throws ApiError
     */
    public static async deactivateQueryInvite({
        key,
        id,
    }: {
        /** The UUID for the individuals interaction (query_experts.qe_uuid) or Query ID **/
        key: string,
        /**  **/
        id: string,
    }): Promise<any> {
        const result = await __request({
            method: 'DELETE',
            path: `/user/interaction/${key}/invites/${id}`,
            errors: {
                403: `Error: Forbidden`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Decline an invitation
     * Decline an invitation
     * @result QueryDisplayUpdate successful operation
     * @throws ApiError
     */
    public static async declineInvitation({
        key,
    }: {
        /** The UUID for the individuals interaction (query_experts.qe_uuid) or Query ID **/
        key: string,
    }): Promise<QueryDisplayUpdate> {
        const result = await __request({
            method: 'POST',
            path: `/user/interaction/${key}/decline`,
            errors: {
                403: `Error: Forbidden`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Get archive info
     * Returns an array of info about an archive if the user has access to it.
     * @result QueryArchiveInfo successful operation
     * @throws ApiError
     */
    public static async getArchiveInfo({
        key,
        id,
    }: {
        /** The UUID for the individuals interaction (query_experts.qe_uuid) or Query ID **/
        key: string,
        /** Archive Id **/
        id: string,
    }): Promise<QueryArchiveInfo> {
        const result = await __request({
            method: 'GET',
            path: `/user/interaction/${key}/archive/${id}`,
            errors: {
                403: `Error: Forbidden`,
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Get attachment info
     * Returns an array of info about an attachment if the user has access to it.
     * @result QueryAttachmentInfo successful operation
     * @throws ApiError
     */
    public static async getAttachmentInfo({
        key,
        id,
    }: {
        /** The UUID for the individuals interaction (query_experts.qe_uuid) or Query ID **/
        key: string,
        /** Attachment Id **/
        id: string,
    }): Promise<QueryAttachmentInfo> {
        const result = await __request({
            method: 'GET',
            path: `/user/interaction/${key}/attachment/${id}`,
            errors: {
                403: `Error: Forbidden`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Get query faculty
     * Faculty info, except for blinded queries
     * @result any successful operation
     * @throws ApiError
     */
    public static async getFaculty({
        key,
    }: {
        /** The UUID for the individuals interaction (query_experts.qe_uuid) or Query ID **/
        key: string,
    }): Promise<{
        data: Array<{
            name?: string,
            disclosures?: string,
            affiliations?: string,
        }>,
    }> {
        const result = await __request({
            method: 'GET',
            path: `/user/interaction/${key}/faculty`,
            errors: {
                403: `Error: Forbidden`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Get interaction data display
     * Returns the data necessary to display the interaction, except for responses.
     * @result QueryInteractionDisplayItem successful operation
     * @throws ApiError
     */
    public static async getDiscussion({
        key,
        hideCsrData,
    }: {
        /** The UUID for the individuals interaction (query_experts.qe_uuid) or Query ID **/
        key: string,
        /** Hide CSR specific data? **/
        hideCsrData?: boolean,
    }): Promise<QueryInteractionDisplayItem> {
        const result = await __request({
            method: 'GET',
            path: `/user/interaction/${key}`,
            query: {
                'hideCsrData': hideCsrData,
            },
            errors: {
                403: `Error: Forbidden`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Export Query and Responses
     * Exports Query and Responses
     * @result any File Content
     * @throws ApiError
     */
    public static async getDiscussionExport({
        key,
        afterId,
        hideCsrData = true,
        exportType = 'Word',
    }: {
        /** The UUID for the individuals interaction (query_experts.qe_uuid) or Query ID **/
        key: string,
        /** Retrieve records with ids greater than this **/
        afterId?: number,
        /** Hide CSR Specific data? **/
        hideCsrData?: boolean,
        /** Hide CSR Specific data? **/
        exportType?: 'Word' | 'Excel',
    }): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/user/interaction/${key}/export`,
            query: {
                'afterId': afterId,
                'hideCsrData': hideCsrData,
                'exportType': exportType,
            },
            errors: {
                403: `Error: Forbidden`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * List interaction response entries for display
     * Returns any new responses adter afterId
     * @result any successful operation
     * @throws ApiError
     */
    public static async getDiscussionResponses({
        key,
        afterId,
        hideCsrData,
        type,
        page = 1,
        pageSize = 10,
    }: {
        /** The UUID for the individuals interaction (query_experts.qe_uuid) or Query ID **/
        key: string,
        /** Retrieve records with ids greater than this **/
        afterId?: number,
        /** Hide CSR Specific data? **/
        hideCsrData?: boolean,
        /** Response Type to Retreive **/
        type?: 'all' | 'attachment' | 'csrinfo' | 'qstlog' | 'response' | 'resp_attach',
        /** Page to begin results at **/
        page?: number,
        /** Number of results per page **/
        pageSize?: number,
    }): Promise<{
        data?: Array<QueryInteractionResponseItem>,
        meta?: meta,
    }> {
        const result = await __request({
            method: 'GET',
            path: `/user/interaction/${key}/responses`,
            query: {
                'afterId': afterId,
                'hideCsrData': hideCsrData,
                'type': type,
                'page': page,
                'pageSize': pageSize,
            },
            errors: {
                403: `Error: Forbidden`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Get Header QA Data
     * Get Header QA Data
     * @result any successful operation
     * @throws ApiError
     */
    public static async getHeaderQaData({
        key,
    }: {
        /** The UUID for the individuals interaction (query_experts.qe_uuid) or Query ID **/
        key: string,
    }): Promise<{
        /**
         * Grade
         */
        grade?: number,
        /**
         * Last Update Timestamp
         */
        updated?: number,
    }> {
        const result = await __request({
            method: 'GET',
            path: `/csradmin/interaction/${key}/headerQa`,
            errors: {
                403: `Error: Forbidden`,
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Set Header QA Data and query category
     * Get Header QA Data and query category
     * @result QueryDisplayUpdate successful operation
     * @throws ApiError
     */
    public static async setHeaderQaData({
        key,
        requestBody,
    }: {
        /** The UUID for the individuals interaction (query_experts.qe_uuid) or Query ID **/
        key: string,
        /** QA Params **/
        requestBody: {
            /**
             * Query Category
             */
            category?: string,
            /**
             * Query Score 1-100
             */
            score?: number,
        },
    }): Promise<QueryDisplayUpdate> {
        const result = await __request({
            method: 'POST',
            path: `/csradmin/interaction/${key}/headerQa`,
            body: requestBody,
            errors: {
                403: `Error: Forbidden`,
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Get Response QA Data
     * Get Response QA Data
     * @result any successful operation
     * @throws ApiError
     */
    public static async getResponseQaData({
        key,
        responseId,
    }: {
        /** The UUID for the individuals interaction (query_experts.qe_uuid) or Query ID **/
        key: string,
        /** The response Id **/
        responseId: string,
    }): Promise<{
        /**
         * Grade
         */
        score?: number,
        /**
         * Estimated time to complete
         */
        time?: number,
        /**
         * Last Update Timestamp
         */
        updated?: number,
        /**
         * Response class
         */
        class?: string,
    }> {
        const result = await __request({
            method: 'GET',
            path: `/csradmin/interaction/${key}/responseQa/${responseId}`,
            errors: {
                403: `Error: Forbidden`,
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Set Response QA Data and query category
     * Set Response QA Data and query category
     * @result QueryDisplayUpdate successful operation
     * @throws ApiError
     */
    public static async setResponseQaData({
        key,
        responseId,
        requestBody,
    }: {
        /** The UUID for the individuals interaction (query_experts.qe_uuid) or Query ID **/
        key: string,
        /** The response Id **/
        responseId: string,
        /** QA Params **/
        requestBody: {
            /**
             * Response Category
             */
            class?: string,
            /**
             * Query Score 1-100
             */
            score?: number,
            /**
             * Time in minutes >= 0
             */
            time?: number,
        },
    }): Promise<QueryDisplayUpdate> {
        const result = await __request({
            method: 'POST',
            path: `/csradmin/interaction/${key}/responseQa/${responseId}`,
            body: requestBody,
            errors: {
                403: `Error: Forbidden`,
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Invite a Query Participant
     * Invite a Query Participant
     * @result QueryDisplayUpdate successful operation
     * @throws ApiError
     */
    public static async inviteParticipant({
        key,
        requestBody,
    }: {
        /** The UUID for the individuals interaction (query_experts.qe_uuid) or Query ID **/
        key: string,
        /** Invite Participant **/
        requestBody: {
            /**
             * User to Invite
             */
            userId?: number,
            /**
             * Function for user in query
             */
            function?: string,
        },
    }): Promise<QueryDisplayUpdate> {
        const result = await __request({
            method: 'POST',
            path: `/csradmin/interaction/${key}/inviteParticipant`,
            body: requestBody,
            errors: {
                403: `Error: Forbidden`,
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * List Applicable Experts
     * ist Applicable Experts
     * @result any successful operation
     * @throws ApiError
     */
    public static async listApplicableExperts({
        key,
        requestBody,
    }: {
        /** The UUID for the individuals interaction (query_experts.qe_uuid) or Query ID **/
        key: string,
        /** List Applicable Experts **/
        requestBody: {
            /**
             * Diagnosis Filter
             */
            diagnosisId?: number,
            /**
             * Filter Text (based on name)
             */
            queryText?: string,
        },
    }): Promise<{
        data: Array<QueryApplicableExpert>,
    }> {
        const result = await __request({
            method: 'POST',
            path: `/csradmin/interaction/${key}/listApplicableExperts`,
            body: requestBody,
            errors: {
                403: `Error: Forbidden`,
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * List Query Participants
     * List Query Participants
     * @result any successful operation
     * @throws ApiError
     */
    public static async listParticipants({
        key,
        requestBody,
    }: {
        /** The UUID for the individuals interaction (query_experts.qe_uuid) or Query ID **/
        key: string,
        /** List Participants **/
        requestBody: {
            expertStatus?: 'invited' | 'declined' | 'claimed' | 'missedout' | 'latereview' | 'timedout' | 'cancelled' | 'answered' | 'followup' | 'user' | 'csr',
        },
    }): Promise<{
        data: Array<QueryParticipantListItem>,
    }> {
        const result = await __request({
            method: 'POST',
            path: `/csradmin/interaction/${key}/listParticipants`,
            body: requestBody,
            errors: {
                403: `Error: Forbidden`,
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Get eligible team members for shared queries
     * Get eligible team members for shared queries
     * @result any successful operation
     * @throws ApiError
     */
    public static async listTeamMembers({
        key,
    }: {
        /** The UUID for the individuals interaction (query_experts.qe_uuid) or Query ID **/
        key: string,
    }): Promise<{
        data: Array<QueryParticipantListItem>,
    }> {
        const result = await __request({
            method: 'GET',
            path: `/csradmin/interaction/${key}/team`,
            errors: {
                403: `Error: Forbidden`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Get Live Session Status
     * Get Live Session Status
     * @result LiveSessionStatusInfo successful operation
     * @throws ApiError
     */
    public static async liveSessionStatus({
        key,
        isPreview,
    }: {
        /** The UUID for the individuals interaction (query_experts.qe_uuid) or Query ID **/
        key: string,
        /**  **/
        isPreview?: string,
    }): Promise<LiveSessionStatusInfo> {
        const result = await __request({
            method: 'GET',
            path: `/user/interaction/${key}/sessionStatus`,
            query: {
                'isPreview': isPreview,
            },
            errors: {
                403: `Error: Forbidden`,
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Post an attachment resopnse
     * Post an attachment response from the user
     * @result QueryDisplayUpdate successful operation
     * @throws ApiError
     */
    public static async postAttachment({
        key,
        requestBody,
    }: {
        /** The UUID for the individuals interaction (query_experts.qe_uuid) or Query ID **/
        key: string,
        /** Post Attachment **/
        requestBody: any,
    }): Promise<QueryDisplayUpdate> {
        const result = await __request({
            method: 'POST',
            path: `/user/interaction/${key}/attachment`,
            body: requestBody,
            errors: {
                403: `Error: Forbidden`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Post a textual resopnse
     * Post a textual response from the user
     * @result QueryDisplayUpdate successful operation
     * @throws ApiError
     */
    public static async postResponse({
        key,
        requestBody,
    }: {
        /** The UUID for the individuals interaction (query_experts.qe_uuid) or Query ID **/
        key: string,
        /** Post Response **/
        requestBody: {
            /**
             * Response Text
             */
            text: string,
            terms?: {
                /**
                 * CMD Document Id
                 */
                cmsId?: string,
                /**
                 * CMS Document Language
                 */
                language: string,
                /**
                 * CMS Document Last Published
                 */
                updated: string,
            },
        },
    }): Promise<QueryDisplayUpdate> {
        const result = await __request({
            method: 'POST',
            path: `/user/interaction/${key}/response`,
            body: requestBody,
            errors: {
                403: `Error: Forbidden`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Release Query from CSR Hold
     * Release Query from CSR Hold
     * @result QueryDisplayUpdate successful operation
     * @throws ApiError
     */
    public static async releaseHold({
        key,
    }: {
        /** The UUID for the individuals interaction (query_experts.qe_uuid) or Query ID **/
        key: string,
    }): Promise<QueryDisplayUpdate> {
        const result = await __request({
            method: 'POST',
            path: `/csradmin/interaction/${key}/releaseHold`,
            errors: {
                403: `Error: Forbidden`,
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Reset Invite Time
     * Reset the invitation time to now. Resets the clock for automated actions.
     * @result QueryDisplayUpdate successful operation
     * @throws ApiError
     */
    public static async resetInviteTime({
        key,
    }: {
        /** The UUID for the individuals interaction (query_experts.qe_uuid) or Query ID **/
        key: string,
    }): Promise<QueryDisplayUpdate> {
        const result = await __request({
            method: 'POST',
            path: `/csradmin/interaction/${key}/resetInviteTime`,
            errors: {
                403: `Error: Forbidden`,
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Run Expert Selection on Specified Query
     * Run Expert Selection on Specified Query
     * @result QueryDisplayUpdate successful operation
     * @throws ApiError
     */
    public static async runExpertSelection({
        key,
    }: {
        /** The UUID for the individuals interaction (query_experts.qe_uuid) or Query ID **/
        key: string,
    }): Promise<QueryDisplayUpdate> {
        const result = await __request({
            method: 'POST',
            path: `/csradmin/interaction/${key}/runExpertSelection`,
            errors: {
                403: `Error: Forbidden`,
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Set flag for preventing invite automation
     * Set Flag for preventing invite automation
     * @result QueryDisplayUpdate successful operation
     * @throws ApiError
     */
    public static async setCsrReview({
        key,
        requestBody,
    }: {
        /** The UUID for the individuals interaction (query_experts.qe_uuid) or Query ID **/
        key: string,
        /**  **/
        requestBody?: {
            /**
             * CSR Review Flag
             */
            csrReview?: boolean,
        },
    }): Promise<QueryDisplayUpdate> {
        const result = await __request({
            method: 'PUT',
            path: `/csradmin/interaction/${key}/setCSRReview`,
            body: requestBody,
            errors: {
                403: `Error: Forbidden`,
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Set flag for preventing invite automation
     * Set Flag for preventing invite automation
     * @result QueryDisplayUpdate successful operation
     * @throws ApiError
     */
    public static async setPreventInvites({
        key,
    }: {
        /** The UUID for the individuals interaction (query_experts.qe_uuid) or Query ID **/
        key: string,
    }): Promise<QueryDisplayUpdate> {
        const result = await __request({
            method: 'POST',
            path: `/csradmin/interaction/${key}/setPreventInvites`,
            errors: {
                403: `Error: Forbidden`,
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Update Live Session Status
     * Update the ession of a live status
     * @result QueryDisplayUpdate successful operation
     * @throws ApiError
     */
    public static async updateLiveSessionStatus({
        key,
        requestBody,
    }: {
        /** The UUID for the individuals interaction (query_experts.qe_uuid) or Query ID **/
        key: string,
        /** new Live Session Status **/
        requestBody: {
            /**
             * Status
             */
            status?: 'sessionComplete' | 'paymentComplete',
        },
    }): Promise<QueryDisplayUpdate> {
        const result = await __request({
            method: 'POST',
            path: `/csradmin/interaction/${key}/updateLiveSessionStatus`,
            body: requestBody,
            errors: {
                403: `Error: Forbidden`,
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Update Live Session Times for Experts
     * Update Live Session Times for Experts
     * @result QueryDisplayUpdate successful operation
     * @throws ApiError
     */
    public static async updateLiveSessionTimes({
        key,
        requestBody,
    }: {
        /** The UUID for the individuals interaction (query_experts.qe_uuid) or Query ID **/
        key: string,
        /** Live Session times **/
        requestBody: {
            times?: Array<Array<number>>,
        },
    }): Promise<QueryDisplayUpdate> {
        const result = await __request({
            method: 'POST',
            path: `/csradmin/interaction/${key}/updateLiveSessionTimes`,
            body: requestBody,
            errors: {
                403: `Error: Forbidden`,
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Update Meeting Time
     * Update Meeting Time
     * @result QueryDisplayUpdate successful operation
     * @throws ApiError
     */
    public static async updateMeetingTime({
        key,
        requestBody,
    }: {
        /** The UUID for the individuals interaction (query_experts.qe_uuid) or Query ID **/
        key: string,
        /** New Meeting Time **/
        requestBody: {
            /**
             * New meeting time
             */
            meetingTime?: number,
        },
    }): Promise<QueryDisplayUpdate> {
        const result = await __request({
            method: 'POST',
            path: `/csradmin/interaction/${key}/updateMeetingTime`,
            body: requestBody,
            errors: {
                403: `Error: Forbidden`,
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Update Panel Size
     * Update Panel Size
     * @result QueryDisplayUpdate successful operation
     * @throws ApiError
     */
    public static async updatePanelSize({
        key,
        requestBody,
    }: {
        /** The UUID for the individuals interaction (query_experts.qe_uuid) or Query ID **/
        key: string,
        /** New Panel Size **/
        requestBody: {
            /**
             * New Max Experts - Must be greater than current number of engaged experts
             */
            maxExperts?: number,
        },
    }): Promise<QueryDisplayUpdate> {
        const result = await __request({
            method: 'POST',
            path: `/csradmin/interaction/${key}/updatePanelSize`,
            body: requestBody,
            errors: {
                403: `Error: Forbidden`,
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Update Participant Meeting URL
     * Update the participant Meeting URL.
     * @result QueryDisplayUpdate successful operation
     * @throws ApiError
     */
    public static async updateParticipantMtgUrl({
        key,
        requestBody,
    }: {
        /** The UUID for the individuals interaction (query_experts.qe_uuid) or Query ID **/
        key: string,
        /** New Mtg URL **/
        requestBody: {
            /**
             * Meeting URL
             */
            mtgUrl?: string,
            /**
             * Query Expert User Id
             */
            expertId?: number,
        },
    }): Promise<QueryDisplayUpdate> {
        const result = await __request({
            method: 'PUT',
            path: `/csradmin/interaction/${key}/updateParticipantMtgUrl`,
            body: requestBody,
            errors: {
                403: `Error: Forbidden`,
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Update Participant Status
     * Update the participant status. Delete valid for CSRHOLD and User Invited only.
     * @result QueryDisplayUpdate successful operation
     * @throws ApiError
     */
    public static async updateParticipantStatus({
        key,
        requestBody,
    }: {
        /** The UUID for the individuals interaction (query_experts.qe_uuid) or Query ID **/
        key: string,
        /** New Participant Status **/
        requestBody: {
            /**
             * Status
             */
            status?: 'invited' | 'cancelled' | 'delete',
            /**
             * Query Expert User Id
             */
            expertId?: number,
        },
    }): Promise<QueryDisplayUpdate> {
        const result = await __request({
            method: 'POST',
            path: `/csradmin/interaction/${key}/updateParticipantStatus`,
            body: requestBody,
            errors: {
                403: `Error: Forbidden`,
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Scheduled Query Close Time
     * Scheduled Query Close Time
     * @result QueryDisplayUpdate successful operation
     * @throws ApiError
     */
    public static async updateQueryCloseTime({
        key,
        requestBody,
    }: {
        /** The UUID for the individuals interaction (query_experts.qe_uuid) or Query ID **/
        key: string,
        /**  **/
        requestBody: {
            /**
             * Unix TimeStamp for Query Close Time
             */
            closeTime?: number,
        },
    }): Promise<QueryDisplayUpdate> {
        const result = await __request({
            method: 'PUT',
            path: `/csradmin/interaction/${key}/updateQueryCloseTime`,
            body: requestBody,
            errors: {
                403: `Error: Forbidden`,
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Update Query Question
     * Update the query question (text).
     * @result QueryDisplayUpdate successful operation
     * @throws ApiError
     */
    public static async updateQueryQuestion({
        key,
        requestBody,
    }: {
        /** The UUID for the individuals interaction (query_experts.qe_uuid) or Query ID **/
        key: string,
        /** New Panel Size **/
        requestBody: {
            /**
             * Query Question
             */
            question?: string,
            /**
             * Query Question History
             */
            history?: string,
        },
    }): Promise<QueryDisplayUpdate> {
        const result = await __request({
            method: 'POST',
            path: `/csradmin/interaction/${key}/updateQueryQuestion`,
            body: requestBody,
            errors: {
                403: `Error: Forbidden`,
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Get Next Live Session info
     * Mainly for EMO
     * @result any successful operation
     * @throws ApiError
     */
    public static async getNextSessionInfo(): Promise<{
        /**
         * Is there a session?
         */
        found: boolean,
        /**
         * When will session start
         */
        minutes_to_start?: number,
        /**
         * Query Id
         */
        query_id?: number,
        /**
         * Formatted Meeting Time
         */
        meeting_time?: string,
        /**
         * UUID
         */
        uuid?: string,
        /**
         * Experts Last Name
         */
        expert?: string,
    }> {
        const result = await __request({
            method: 'GET',
            path: `/user/nextLiveSession`,
            errors: {
                403: `Error: Forbidden`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

    /**
     * Get Next Live Session info
     * Mainly for EMO
     * @result any successful operation
     * @throws ApiError
     */
    public static async getNextSessionJoinLink(): Promise<{
        /**
         * Join Url
         */
        url: string,
        /**
         * Dial In Data Array
         */
        dialinData: Array<{
            /**
             * Meeting ID
             */
            meetingId?: string,
            /**
             * Meeting Pin
             */
            meetingPin?: string,
            /**
             * Dial In Numbers
             */
            dialIn?: Array<any>,
        }>,
    }> {
        const result = await __request({
            method: 'GET',
            path: `/user/nextLiveSessionLink`,
            errors: {
                403: `Error: Forbidden`,
                404: `Error: Not Found`,
                422: `Error: Unprocessable Entity`,
            },
        });
        return result.body;
    }

}