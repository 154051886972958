/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import Vue from "vue";
import { Module } from "vuex-typescript-interface";
import { Document } from "@prismicio/client/types/documents";
import { Languages } from "@/config";
import { IndexStoreInterface } from "./types";
import { RootStoreInterface } from "../types";

import { QueryDisplayUpdate, QueryIndexFullDisplay, queryBuckets, QueryAggregationItem, QueryService } from "@/service";

const index: Module<IndexStoreInterface, RootStoreInterface> = {
  state: {
    // Query Index State
    indexRecords: {} as queryBuckets,
    indexQueryStats: {} as QueryAggregationItem,
    indexSelected: null,
    indexSelectedIndex: null,
    indexPage: 0,
    indexMaxPages: 0,
    indexLoading: false,
    indexQueryFilter: {},
    indexTotalRecords: 0,
    indexQueryBucketTypes: {
      requiresAction: "Requiring Action",
      newOpportunities: "New Opportunities",
      ongoing: "Ongoing Discussion",
      closed: "Closed" //Previous
    },
    indexDocumentData: undefined
  },

  getters: {
    // Query Index
    records: state => state.indexRecords,
    queryStats: state => state.indexQueryStats,

    selected: state => state.indexSelected,
    selectedIndex: state => {
      if (state.indexSelected) {
        const id = state.indexSelected.queryId;
        for (const [key, val] of Object.entries(state.indexRecords)) {
          const index = val?.data?.findIndex(r => r.queryId == id) ?? -1;
          if (index > -1)
            return `${key}-${index}`;
        }
      }
      return null;
      // state.indexSelectedIndex
    }, // maybe should be a find in case the list changes....
    page: state => state.indexPage,
    maxPages: state => state.indexMaxPages,
    loading: state => state.indexLoading,
    queryFilter: state => state.indexQueryFilter,
    totalRecords: state => state.indexTotalRecords,
    queryBucketTypes: state => state.indexQueryBucketTypes,

    indexDocument: state => state.indexDocumentData,
    indexDocContent: state => state.indexDocumentData?.data,
    indexDocUpdated: state => state.indexDocumentData?.last_publication_date || undefined,
    indexDocLanguage: state => state.indexDocumentData?.lang,
    indexDocId: state => state.indexDocumentData?.id
  },

  mutations: {
    // Index mutations
    clearIndexRecords(state): void {
      state.indexRecords = {};
    },
    setIndexRecords(state, r: queryBuckets): void {
      state.indexRecords = r;
    },
    setIndexStats(state, s: QueryAggregationItem): void {
      state.indexQueryStats = s;
    },
    updateIndexRecord(state, { id, rec }: { id: number; rec: QueryIndexFullDisplay }): void {
      for (const [_, val] of Object.entries(state.indexRecords)) {
        const index = val?.data?.findIndex(r => r.queryId == id) ?? -1;
        if (index > -1) {
          val?.data?.splice(index, 1, rec);
          return;
        }
      }
    },
    removeIndexRecord(state, id: number): void {
      for (const [key, val] of Object.entries(state.indexRecords)) {
        const index = val?.data?.findIndex(r => r.queryId == id) ?? -1;
        if (index > -1) {
          val?.data?.splice(index, 1);
          state.indexTotalRecords = state.indexTotalRecords - 1;
          state.indexQueryStats.totalQueries = (state.indexQueryStats.totalQueries ?? 1) - 1;
          switch(key) {
            case "newOpportunities":
              state.indexQueryStats.newOpportunities = (state.indexQueryStats.newOpportunities ?? 1) - 1;
              break;
            case "requiresAction":
              state.indexQueryStats.requiresAction = (state.indexQueryStats.requiresAction ?? 1) - 1;
              break;
            case "ongoing":
              state.indexQueryStats.ongoing = (state.indexQueryStats.ongoing ?? 1) - 1;
              break;
            case "closed":
              state.indexQueryStats.closed = (state.indexQueryStats.closed ?? 1) - 1;
              break;
          }
          return;
        }
      }
    },
    selectIndexRecord(state, rec: QueryIndexFullDisplay): void {
      for (const [key, val] of Object.entries(state.indexRecords)) {
        const index = val?.data?.findIndex(r => r.queryId == rec.queryId) ?? -1;
        if (index > -1) {
          state.indexSelected = val?.data?.[index] ?? null;
          state.indexSelectedIndex = `${key}-${index}`;
          return;
        }
      }
      state.indexSelected = null;
      state.indexSelectedIndex = null;
    },
    selectIndexRecordByIndex(state, index: string | null): void {
      if (index && index.length) {
        for (const [key, val] of Object.entries(state.indexRecords)) {
          state.indexSelected = val?.data?.find((_, i) => `${key}-${i}` == index) ?? null;
          if (state.indexSelected) {
            state.indexSelectedIndex = index;
            return;
          }
        }
      }
      state.indexSelected = null;
      state.indexSelectedIndex = null;
    },
    setLoading(state, b: boolean): void {
      state.indexLoading = b;
    },
    setTotalRecords(state, n: number): void {
      state.indexTotalRecords = n;
    },
    setMaxPages(state, n: number): void {
      state.indexMaxPages = n;
    },
    setPage(state, n: number): void {
      state.indexPage = n;
    },
    setQueryFilter(state, f: Record<string, any>): void {
      state.indexQueryFilter = f;
    },
    resetIndex(state): void {
      state.indexRecords = {};
      state.indexQueryStats = {};
      state.indexSelected = null;
      state.indexSelectedIndex = null;
      state.indexPage = 0;
      state.indexMaxPages = 0;
      state.indexLoading = true;
      state.indexQueryFilter = {};
      state.indexTotalRecords = 0;
    },
    setReload(state): void {
      state.indexRecords = {};
      state.indexQueryStats = {};
      state.indexPage = 0;
      state.indexMaxPages = 0;
      state.indexTotalRecords = 0;
    },
    setIndexDocument(state, doc: Document | undefined): void {
      state.indexDocumentData = doc;
    }
  },

  actions: {
    // QueryIndex Actions
    async loadIndex({ commit, state, rootGetters }): Promise<boolean> {
      commit("setLoading", true);
      try {
        const page = 1; //state.indexPage + 1;
        const payload = Object.assign({}, state.indexQueryFilter, {
          // text: this.search?.trim(),
          // status: this.queryStatus && this.queryStatus.length ? this.queryStatus : undefined,
          page: page,
          pageSize: 100,
          sort: undefined
        });
        const r = await QueryService.getQueryIndexBuckets(payload as Record<string, unknown>);
        let totalRecords = 0;
        if (r?.data) {
          commit("setIndexRecords", r.data);
          for (const [_, val] of Object.entries(state.indexRecords)) {
            totalRecords += val?.data?.length ?? 0;
          }
        }
        if (r?.queryStats) {
          if (!rootGetters.user?.noeticExpert) {  
            // Non noetic expert recoord counts only ongoing and closed
            commit("setTotalRecords", (r.queryStats?.ongoing ?? 0) + (r.queryStats?.closed ?? 0));
          } else {
            commit("setTotalRecords", r.queryStats?.totalQueries ?? totalRecords);
          }
          commit("setIndexStats", r.queryStats);
        } else {
          commit("setTotalRecords", totalRecords);
        }
        commit("setPage", page);
        commit("setMaxPages", 1);
        commit("setLoading", false);
        return !!totalRecords;
      } catch (e) {
        commit("setLoading", false);
        throw e;
      }
    },
    async processDisplayUpdate({ commit, state }, update: QueryDisplayUpdate): Promise<void> {
      if (!update.item) {
        // If this item is selected, deselect it first
        if (state.indexSelected?.queryId == update.id) {
          commit("selectIndexRecordByIndex", null);
        }
        commit("removeIndexRecord", update.id);
      } else {
        commit("updateIndexRecord", { id: update.id, rec: update.item });
      }
    },

    async loadIndexCms(
      { commit, rootState, rootGetters },
      docType: string
    ): Promise<Document | undefined> {
      if (!docType || !docType.length) return undefined;
      try {
        const currentUserType = rootGetters.activeUserType;
        if (!currentUserType || !(rootGetters.userTypes[currentUserType] ?? null)) {
          commit("setIndexDocument", undefined);
          return undefined;
        }
        const currentLocale = Languages[rootState.lang]?.locale ?? "en-us";
        const userType = rootGetters.userTypes[currentUserType].toLowerCase().replace(' ', '')
        const prismic = Vue.prototype.$prismic;
        const query = [
          prismic.Predicates.at("document.type", docType),
          prismic.Predicates.at(`my.${docType}.user-type.user_type`, userType),
        ];
        let resp = await prismic.client.query(query, {
          lang: currentLocale,
          orderings: "[document.last_publication_date desc]"
        });
        if ((!resp || !resp.results.length) && currentLocale != "en-us") {
          resp = await prismic.client.query(query, {
            lang: "en-us",
            orderings: "[document.last_publication_date desc]"
          });
        }
        const doc = resp?.results?.length ? resp.results[0] : undefined;
        commit("setIndexDocument", doc);
        return doc;
      } catch (e) {
        console.log({ e });
        commit("setIndexDocument", undefined);
        return undefined;
      }
    },
    async clearIndexCms({ commit }): Promise<void> {
      commit("setIndexDocument", undefined);
    }
  }
};

export default index;
