<template>
  <v-dialog v-model="visible" max-width="600" eage persistent @keydown.esc="false">
    <v-card class="px-2 pt-3 pb-2">
      <v-card-text v-if="visible" class="py-3 px-4 text-body-1 text--darken-1 font-weight-bold" v-html="$t('messages.sessionTimeout', {time: remainingTime})" />
      <v-card-actions>
        <v-spacer />
        <o-btn class="px-4" :loading="loading" @click="stay">{{ $t('captions.stay') }}</o-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import Vue from "vue";
import OBtn from "@/framework/OBtn.vue";

import { createHelpers } from "vuex-typescript-interface";
import { RootStoreInterface } from "@/store/types";
const { mapActions } = createHelpers<RootStoreInterface>();
const actions = mapActions(["refreshWebToken"]);

export default Vue.extend({
  components: {
    OBtn
  },
  data: () => ({
    timerHandle: undefined as NodeJS.Timeout | undefined,
    remainingTimeCount: 0,
    loading: false
  }),
  props: {
    value: {
      type: Boolean,
      default: false
    },
    expiryTime: {
      type: Number,
      default: 0
    } 
  },
  computed: {
    visible: {
      get(): boolean {
        return this.value;
      },
      set(val: boolean) {
        this.$emit('input', val);
      }
    },
    remainingTime(): string {
      const minutes = Math.floor(this.remainingTimeCount / 60);
      const seconds =  Math.floor(this.remainingTimeCount - minutes * 60);
      return String(minutes).padStart(2, '0') + ':' + String(seconds).padStart(2, '0');
    }
  },
  methods: {
    ...actions,
    startTimer() {
      this.stopTimer();
      // Check if use is already timed out
      if (!this.expiryTime || this.expiryTime < Date.now() / 1000) return;
      this.timerHandle = setInterval(() => {
        this.remainingTimeCount = Math.floor(this.expiryTime - (Date.now() / 1000));
        if (this.remainingTimeCount <= 0 && this.visible) this.visible = false;
        else if (this.remainingTimeCount <= 60 && !this.visible) this.visible = true;
      }, 1000);
    },
    stopTimer() {
      if (this.timerHandle) {
        clearInterval(this.timerHandle);
        this.timerHandle = undefined;
      }
    },
    async stay() {
      if (Math.floor(this.expiryTime - (Date.now() / 1000)) > 60) {
        this.dismiss();
        return;
      }
      this.loading = true;
      await this.refreshWebToken();
      this.loading = false;
    },
    reloadTimer() {
      if (this.expiryTime > Date.now() / 1000) this.startTimer();
      else if (this.expiryTime <=0 ) this.stopTimer();
    },
    dismiss() {
      this.visible = false;
    }
  },
  watch: {
    visible(val: boolean) {
      if (!val) this.stopTimer();
    },
    expiryTime(val: number) {
      this.reloadTimer();
    }
  },
  created() {
    this.reloadTimer();
  }
});
</script>

<style lang="scss" scoped>
@import "@/scss/local.scss";
.v-card {
  border-radius: $o-border-radius;
}
</style>
